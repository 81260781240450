import SearchInput from 'components/search-input'
import styles from './search.module.scss'

const SearchBlock = () => (
  <div className={styles.wrapper}>
    <span className={styles.title}>FIND TICKETS TO YOUR NEXT BEST-NIGHT-EVER</span>
    <SearchInput
      inputPlaceholder="Search by artist, event or venue "
      types={['stars', 'venues', 'events', 'tours']}
      debounceInMs={500}
    />
  </div>
)

export default SearchBlock
