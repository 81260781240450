import { FC } from 'react'
import SwiperContainer from '~shared/Swiper'
import { Hero, Wrapper } from '~ui'

import styles from './hero-slider.module.scss'

interface HeroSliderProps {
  interval: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slides: any[]
}

const HeroSlider: FC<HeroSliderProps> = ({ interval, slides = [] }) => (
  <Wrapper>
    <div className={styles['featured-slider']}>
      <SwiperContainer interval={parseInt(interval, 10)}>
        {slides
          .sort((a, b) => a.order - b.order)
          .map((item) => (
            <Hero key={item.title} {...item} image={item.images ? item.images[0] : {}} />
          ))}
      </SwiperContainer>
    </div>
  </Wrapper>
)

export default HeroSlider
