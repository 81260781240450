import classnames from 'classnames'
import { Children } from 'react'
import { useSwiper } from '~hooks'
import { IconButton } from '~ui'

import styles from './Swiper.module.scss'

interface SwiperContainerProps {
  slideStyles?: string
  interval?: number
  showControls?: boolean
  showDots?: boolean
}

const SwiperContainer: React.FC<SwiperContainerProps> = ({
  children,
  slideStyles,
  interval,
  showControls = true,
  showDots = true,
}) => {
  const items = Children.map(children, (c) => c)

  const { index, setIndex, onNext, onPrev, smooth, galleryRef, slidesRef } = useSwiper({
    slides: items as [],
    interval: interval || 0,
  })

  return (
    <div style={{ position: 'relative' /* for buttons */ }}>
      <div className={styles.container} ref={galleryRef}>
        <div className={styles.slides} style={{ display: 'grid' }} ref={slidesRef}>
          {items.map((item, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              className={classnames(styles.slide, slideStyles, { [styles.smooth]: smooth })}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      {showControls && (
        <div className={styles['control-buttons']}>
          <IconButton iconName="left-tail-arrow" onClick={onPrev} />
          <IconButton iconName="right-tail-arrow" onClick={onNext} />
        </div>
      )}
      {showDots && (
        <div className={styles.dots}>
          {items.map((item, i) => (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              aria-hidden
              className={classnames(styles.dot, { [styles['dot-active']]: i === index })}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SwiperContainer
