import { useState, useRef, useCallback, useEffect } from 'react'

export type useSwiperParams = {
  swipeCoefficient?: number
  interval?: number
  slides: []
}

const useSwiper = ({ interval, slides, swipeCoefficient = 0.35 }: useSwiperParams) => {
  const [index, setIndex] = useState(0)
  const [smooth, setSmooth] = useState(true)

  const slidesRef = useRef<HTMLDivElement>()
  const setTimeoutRef = useRef<NodeJS.Timeout>()
  const galleryRef = useRef<HTMLDivElement>()

  const x0 = useRef<number>()
  const y0 = useRef<number>()

  const isLastSlide = index === slides.length - 1
  const isFirstSlide = index === 0

  const onNext = useCallback(() => {
    if (isLastSlide) {
      setIndex(0)
      return
    }

    // eslint-disable-next-line
    setIndex((prev) => ++prev)
  }, [isLastSlide])

  const onPrev = () => {
    if (isFirstSlide) {
      setIndex(slides.length - 1)
      return
    }

    // eslint-disable-next-line
    setIndex((prev) => --prev)
  }

  const onMoveStart = (e: TouchEvent) => {
    x0.current = e.changedTouches[0].clientX
    y0.current = e.changedTouches[0].clientY
    setSmooth(false)
  }

  const THRESHOLD = 30

  const onMove = (e: TouchEvent) => {
    if (!slidesRef) return

    const x1 = e.changedTouches[0].clientX
    const y1 = e.changedTouches[0].clientY
    const diffX = x1 - x0.current
    const diffY = y1 - y0.current

    const isScroll = Math.abs(diffX) < Math.abs(diffY)

    if (isScroll) {
      return
    }

    if (Math.abs(diffX) < THRESHOLD) return

    document.body.style.overflow = 'hidden'

    slidesRef.current.style.setProperty('--tx', `${diffX}px`)
  }

  const onMoveEnd = (e: TouchEvent) => {
    if (!galleryRef.current) return

    document.body.style.overflow = 'auto'

    const x1 = e.changedTouches[0].clientX
    const width = galleryRef.current.offsetWidth
    const diff = x1 - x0.current
    const direction = Math.sign(diff)
    const pathLength = (direction * diff) / width

    if (direction < 0 && pathLength >= swipeCoefficient && !isLastSlide) {
      onNext()
    }

    if (direction > 0 && pathLength >= swipeCoefficient && !isFirstSlide) {
      onPrev()
    }

    slidesRef.current.style.removeProperty('--tx')
    setSmooth(true)
  }

  useEffect(() => {
    if (interval) {
      setTimeoutRef.current = setTimeout(() => {
        onNext()
      }, interval * 1000)
    }
    return () => {
      if (setTimeoutRef.current) clearTimeout(setTimeoutRef.current)
    }
  }, [index, interval, onNext])

  useEffect(() => {
    slidesRef.current.style.setProperty('--i', index.toString())
    slidesRef.current.style.setProperty('--items-count', slides.length.toString())
  }, [index])

  useEffect(() => {
    galleryRef.current?.addEventListener('touchstart', onMoveStart)
    galleryRef.current?.addEventListener('touchmove', onMove)
    galleryRef.current?.addEventListener('touchend', onMoveEnd)

    return () => {
      galleryRef.current?.removeEventListener('touchstart', onMoveStart)
      galleryRef.current?.removeEventListener('touchmove', onMove)
      galleryRef.current?.removeEventListener('touchend', onMoveEnd)
    }
  }, [isFirstSlide, isLastSlide])

  return {
    index,
    smooth,
    onPrev,
    onNext,
    setIndex,
    slidesRef,
    galleryRef,
  }
}

export default useSwiper
