import Carousel from 'components/carousel'
import Wrapper from 'components/ui/Wrapper'
import Card, { CardProps } from 'components/ui/Card'

import SectionWrapper from '../wrapper'

const CardsCarousel = ({ cards, title }) => (
  <Wrapper style={{ padding: '0 0 0 1em' }}>
    <SectionWrapper>
      <Carousel<CardProps>
        title={title}
        dataSource={cards}
        renderItem={({ title: cardTitle, imageSrc, link, linkTitle }) => (
          <Card
            link={link}
            title={cardTitle}
            imageSrc={imageSrc}
            linkTitle={linkTitle}
            aspectRatio="270/160"
          />
        )}
      />
    </SectionWrapper>
  </Wrapper>
)

export default CardsCarousel
