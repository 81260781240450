import classNames from 'classnames'
import { ImgHTMLAttributes, FC, useRef, useEffect } from 'react'
import { useScreenClass } from '~hooks'
import Markdown from '~shared/Markdown'

import Wrapper from '~ui/Wrapper'
import SectionWrapper from '../wrapper'

import styles from './Image.module.scss'

const ImageSection: FC<
  Pick<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt'> & {
    mobileImageUrl: string
    vimeoId: string
    description: string
    alignCenter: string
  }
> = ({ alt, src, mobileImageUrl, vimeoId, description, alignCenter }) => {
  const { isMobile } = useScreenClass()
  const vimeoPlayer = useRef<{ play?: () => void }>({})

  useEffect(() => {
    vimeoPlayer.current?.play?.()
  }, [])

  if (vimeoId)
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Wrapper>
        <SectionWrapper>
          <div className={styles['video-wrapper']}>
            <div className={styles['video-aspect-ratio']}>
              <div className={styles.video}>
                <iframe
                  src={`https://player.vimeo.com/video/${vimeoId}?autoplay=1&loop=1&color=73ff73&title=0&byline=0&portrait=0&muted=1`}
                  allow="autoplay; fullscreen; picture-in-picture"
                  ref={(ref) => {
                    if (ref && window.Vimeo?.Player) {
                      vimeoPlayer.current = new window.Vimeo.Player(ref)
                    }
                  }}
                  title={`${alt}-video-${vimeoId}`}
                  frameBorder="0"
                  id="video"
                />
              </div>
            </div>
          </div>

          {description && (
            <div className={classNames({ [styles.center]: alignCenter })}>
              <Markdown variant="standart" md={description} />
            </div>
          )}
        </SectionWrapper>
      </Wrapper>
    )

  return (
    <Wrapper>
      <SectionWrapper>
        <img
          src={isMobile && mobileImageUrl ? mobileImageUrl : src}
          className={styles.image}
          alt={alt}
        />
      </SectionWrapper>
    </Wrapper>
  )
}

export default ImageSection
