import { FC } from 'react'
import { Carousel } from 'components/shared'
import { Grid, Flex, Wrapper } from '~ui'
import { useScreenClass } from '~hooks'

import Testimonial from '~shared/Testimonial'

type Review = {
  bodyText: string
  customer: {
    imageSrc: string
    name: string
    role: string
  }
}

const Reviews: FC<{ reviews: Review[] }> = ({ reviews }) => {
  const { screenClass } = useScreenClass()

  const sizes = ['sm', 'xs', 'md']

  const itemsToShow = (screen) => (['sm', 'xs', 'md'].includes(screen) ? 1 : 3)
  const enoughItems = reviews.length > itemsToShow(screenClass)

  return (
    <Wrapper>
      <Grid padding="80px 0" gap="40px">
        <Carousel
          itemsToShow={itemsToShow(screenClass)}
          showControls={!sizes.includes(screenClass) && enoughItems}
          interval={sizes.includes(screenClass) && 5}
        >
          {reviews.map((item) => (
            <Flex key={item.customer.name} justifyContent="center">
              <Testimonial key={item.bodyText} {...item} />
            </Flex>
          ))}
        </Carousel>
      </Grid>
    </Wrapper>
  )
}

export default Reviews
