import classNames from 'classnames'
import { FC, ReactNode } from 'react'

import styles from './neon-box.module.scss'

type NeonBoxType = {
  title: ReactNode
  color?: GThemeColorsNew
  maxWidth?: string
}
const NeonBox: FC<NeonBoxType> = ({ title, children, color, maxWidth }) => (
  <div className={classNames(styles.wrapper, { [styles[color]]: color })} style={{ maxWidth }}>
    <span className={styles.title}>{title}</span>
    {children}
  </div>
)

export default NeonBox
