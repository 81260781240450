import { LIST_SENDGRID_NAMES } from 'constants/constant'
import { SubscribeForm } from 'components/shared'
import NeonBox from '~ui/NeonBox'

import styles from './Auctions-subscribe.module.scss'

const AuctionsSubscribe = () => (
  <div className={styles.form}>
    <NeonBox
      title={
        <>
          The next one is coming soon
          <br /> Sign up to be notified
        </>
      }
    >
      <SubscribeForm
        listNames={[
          LIST_SENDGRID_NAMES.auction_sign_up,
          LIST_SENDGRID_NAMES.general_welcome_series,
        ]}
      />
    </NeonBox>
  </div>
)

export default AuctionsSubscribe
