import { MARKETING } from 'routes/paths'
import SwiperContainer from '~shared/Swiper'
import { Grid, Hero } from '~ui'

const COLORS: GThemeColorsNew[] = ['g-green-darker', 'yellow', 'blue']
const DEFAULT_COLOR: GThemeColorsNew = 'green'

const LiveAuctions = ({ data }) => (
  <Grid margin="20px 0" position="relative">
    <SwiperContainer>
      {data.map(({ settings, id, name, sections }, index) => {
        const marketingHeroBlockData = sections.find(({ type }) => type === 'marketing-hero-block')
        const lots = sections.find(({ type }) => type === 'lots')?.lots

        return (
          <Hero
            key={id}
            title={settings.pageTitle}
            description={[marketingHeroBlockData.settings.description]}
            image={{
              url: marketingHeroBlockData.settings.mobileImageUrl,
              alt: settings.pageTitle,
            }}
            actions={[
              {
                href: MARKETING(name),
                label: 'View Auction',
                variant: 'primary',
                uppercase: true,
                mode: 'link',
                icon: 'star',
              },
            ]}
            color={COLORS[index] || DEFAULT_COLOR}
            imageLabel={
              !lots?.length && (
                <img
                  style={{ width: '50%', objectFit: 'contain', objectPosition: 'top' }}
                  alt='Label with text "Auction ended"'
                  src="/ended_label.png"
                />
              )
            }
          />
        )
      })}
    </SwiperContainer>
  </Grid>
)

export default LiveAuctions
