import { LIST_SENDGRID_NAMES } from 'constants/constant'
import { useEmailSubsribeContactList } from '~hooks'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { Input, Button, Grid, Result, TextArea } from '~ui'
import { EMAIL } from 'constants/schema'

import styles from './Auctions-form.module.scss'

const AuctionsForm = () => {
  const [subscribe, { data: mutationData, loading }] = useEmailSubsribeContactList()

  const { errors, handleSubmit, values, handleBlur, touched, handleChange } = useFormik({
    initialValues: {
      name: '',
      email: '',
      comment: '',
    },
    onSubmit: ({ email, name, comment }) =>
      subscribe({
        variables: {
          data: { email, firstName: name, customFields: { e10T: comment } },
          listNames: [
            LIST_SENDGRID_NAMES.auction_requests,
            LIST_SENDGRID_NAMES.general_welcome_series,
          ],
        },
      }),
    validationSchema: object({
      email: EMAIL(),
      name: string().required('Please enter your name.'),
      comment: string().required('Please fill your comment.'),
    }).required(),
  })
  return (
    <div className="max-w-2xl mx-auto">
      {mutationData?.addOrUpdateContactList.__typename === 'ContactMutationSuccess' ? (
        <Result title="Success!" subtitle="Thanks for your application!" />
      ) : (
        <div className="glow-box-g-purple glow-box-opacity-40 border p-4 md:p-8">
          <h2 className="text-xl uppercase text-center mb-6 glow-text-g-purple">
            Who would you like to see in a Granted auction?
          </h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <Grid className={styles['name-email-inputs']}>
              <Input
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Your Name"
                error={errors.name && touched.name && errors.name}
              />
              <Input
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Your Email"
                error={errors.email && touched.email && errors.email}
              />
            </Grid>
            <TextArea
              name="comment"
              value={values.comment}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter Your Comment"
              error={errors.comment && touched.comment && errors.comment}
            />
            <Button variant="primary" type="submit" uppercase loading={loading} disabled={loading}>
              Submit
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}

export default AuctionsForm
