import { gql, useQuery } from '@apollo/client'

import Carousel from 'components/carousel'
import { ToursCarouselDataQuery, ToursCarouselDataQueryVariables } from 'gql'
import { TOUR } from 'routes/paths'
import { getImageUrl } from 'utils'
import { useOnScreen } from '~hooks'
import { Card, Wrapper } from '~ui'

import SectionWrapper from '../wrapper'

export const TOURS_CAROUSEL_DATA = gql`
  query ToursCarouselData($ids: String) {
    allTours(ids: $ids) {
      edges {
        node {
          id
          title
          tourPhoto {
            extension
            path
          }
          slug
          artistFullName
        }
      }
    }
  }
`

const ToursSlider = ({ tours = [], title: t = undefined }) => {
  const { innerRef: ref, wasViewed } = useOnScreen()

  const { data, loading } = useQuery<ToursCarouselDataQuery, ToursCarouselDataQueryVariables>(
    TOURS_CAROUSEL_DATA,
    {
      variables: {
        ids: tours.map(({ node }) => node.id).join(','),
      },
      skip: !wasViewed,
    }
  )

  return (
    <Wrapper ref={ref}>
      <SectionWrapper>
        <Carousel<ToursCarouselDataQuery['allTours']['edges'][0]['node']>
          dataSource={data?.allTours.edges
            .map((item) => item.node)
            .sort(
              (a, b) =>
                tours.find(({ value }) => value === a.id)?.order -
                tours.find(({ value }) => value === b.id)?.order
            )}
          title={t}
          renderItem={({ tourPhoto, slug, title, artistFullName }) => (
            <Card
              key={slug}
              imageSrc={getImageUrl(tourPhoto, 300, 200)}
              linkTitle="Get Tickets"
              title={artistFullName}
              link={TOUR(slug)}
              subtitle={title}
            />
          )}
          loading={loading || !wasViewed}
        />
      </SectionWrapper>
    </Wrapper>
  )
}

export default ToursSlider
