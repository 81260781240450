import { FC } from 'react'

import { Grid } from 'components/ui'

import styles from './Section.module.scss'

const SectionWrapper: FC<{ title: string }> = ({ title, children }) => (
  <Grid gap={40} padding="60px 0">
    <h2 className={styles.title}>{title}</h2>
    {children}
  </Grid>
)

export default SectionWrapper
