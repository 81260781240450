import { FC } from 'react'

import Wrapper from '~ui/Wrapper'
import EventsList, { Options } from 'components/shared/EventsList'
import { GetEventsQueryVariables } from 'gql'

import WrapperSection from '../wrapper'

type EventsSectionProps = {
  id?: string
  hideTEvoTickets?: boolean
  queryVariables: GetEventsQueryVariables
  noDataText?: string
  title?: string
} & Options

const EventsSection: FC<EventsSectionProps> = ({
  id,
  queryVariables,
  noDataText,
  title,
  hideTEvoTickets,
  ...options
}) => (
  <Wrapper id={id}>
    <WrapperSection>
      <EventsList
        hideTevoLink={hideTEvoTickets}
        title={title}
        queryOptions={{
          variables: { first: 10, status: 'public', ...queryVariables },
          fetchPolicy: 'network-only',
        }}
        options={options}
        itemListProps={{
          noDataText,
        }}
      />
    </WrapperSection>
  </Wrapper>
)

export default EventsSection
