import classnames from 'classnames'
import { CSSProperties, FC, ReactNode } from 'react'

import GlowHeading, { GlowHeadingProps } from 'components/ui/GlowHeading'
import Button, { ButtonProps } from '~ui/Button'
import { useQueryParams } from '~hooks'
import { hasAnElementAsParent } from '~utils'

import styles from './hero.module.scss'

export type HeroProps = {
  description: ReactNode[] | ReactNode
  image?: {
    url: string
    alt: string
    objectFit?: CSSProperties['objectFit']
    objectPosition?: CSSProperties['objectPosition']
  }
  title?: string
  actions?: Array<ButtonProps & { label?: string }> | (ButtonProps & { label?: string })
  color?: GThemeColorsNew
  headingProps?: Partial<GlowHeadingProps>
  imageLabel?: ReactNode
}

const Hero: FC<HeroProps> = ({
  title,
  imageLabel,
  description,
  actions = [],
  image = {},
  color = 'light-purple',
  headingProps,
}) => {
  const { push } = useQueryParams()

  return (
    <button
      type="button"
      className={styles['link-style']}
      onClick={(e) => {
        if (
          (e.target as HTMLElement).tagName !== 'A' &&
          !hasAnElementAsParent(e.target as HTMLElement, 'A')
        ) {
          push(actions[0].href)
        }
      }}
    >
      <div
        className={classnames(styles.content, {
          [styles[color]]: color,
        })}
      >
        <div className={styles['image-part']}>
          <img
            style={{ objectFit: image.objectFit, objectPosition: image.objectPosition }}
            className={styles.image}
            src={image.url}
            alt={title}
          />

          <div className={styles.label}>{imageLabel}</div>

          <div className={styles.sign}>
            <div />
            <div />
          </div>
        </div>

        <div className={styles['text-part']}>
          <div className={styles.header}>
            <GlowHeading
              Tag="h2"
              color={color}
              uppercase
              className={classnames(styles.title, {
                [styles['turn-off-animation']]:
                  typeof headingProps?.glow !== 'undefined' && !headingProps?.glow,
              })}
              {...headingProps}
            >
              {title}
            </GlowHeading>
          </div>
          <div className={styles.description}>
            {(Array.isArray(description) ? description : [description]).map((desc, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <p key={`description-${index}`}>{desc}</p>
            ))}
          </div>

          <div className={styles['buttons-wrapper']}>
            {(Array.isArray(actions) ? actions : [actions]).map(
              ({ label, ...buttonProps }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Button key={`action-${index}`} clr={color} {...buttonProps}>
                  {label}
                </Button>
              )
            )}
          </div>
        </div>
      </div>
    </button>
  )
}

export default Hero
