import { FC } from 'react'
import Markdown from '~shared/Markdown'

import { Flex, GlowHeading, Typography, Link } from '~ui'

import styles from '../../Marketing.module.scss'

const { Text } = Typography

export type MarketingBlockAboutProps = {
  title?: string
  description?: string
  bannerTitle?: string
  learnMoreUrl?: string
  bannerImageUrl?: string
  bannerDescription?: string
  bannerVideo?: string
}

const MarketingBlockAbout: FC<MarketingBlockAboutProps> = ({
  title,
  description,
  bannerTitle,
  learnMoreUrl,
  bannerImageUrl,
  bannerDescription,
  bannerVideo,
}) => (
  <section>
    <Flex className={styles.about}>
      <Flex className={styles['about-container']}>
        <Flex className={styles['about-content']}>
          {title && (
            <GlowHeading Tag="h2" size="h1">
              {title}
            </GlowHeading>
          )}
          <div style={{ height: 36 }} />
          {description && (
            <Text color="g-gray-1">
              <Markdown md={description} />
            </Text>
          )}
          <div style={{ height: 16 }} />
          <Flex justifyContent="flex-start">
            {learnMoreUrl && (
              <Link href={learnMoreUrl} target="_blank" rel="noreferrer" bold>
                Learn more...
              </Link>
            )}
          </Flex>
        </Flex>

        {bannerVideo ? (
          <div className={styles.video}>
            <video autoPlay loop muted>
              <source src={bannerVideo} type="video/mp4" />
            </video>
            <div className={styles.content}>
              {bannerDescription && (
                <Text color="g-gray-1">
                  <Markdown md={bannerDescription} />
                </Text>
              )}
              {bannerTitle && <GlowHeading Tag="h2">{bannerTitle}</GlowHeading>}
            </div>
          </div>
        ) : (
          <div
            className={styles['about-card']}
            style={{
              background: `url(${bannerImageUrl})`,
              backgroundColor: '#0a0a0a',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            {bannerDescription && (
              <Text color="g-gray-1">
                <Markdown md={bannerDescription} />
              </Text>
            )}
            <div style={{ height: 8 }} />
            {bannerTitle && <GlowHeading Tag="h2">{bannerTitle}</GlowHeading>}
          </div>
        )}
      </Flex>
    </Flex>
  </section>
)

export default MarketingBlockAbout
