import { useQuery } from '@apollo/client'

import Carousel from 'components/carousel'
import { AllCategoriesQuery, AllCategoriesQueryVariables } from 'gql'
import { ALL_CATEGORIES } from 'gql/queries'
import { Card, Grid, Placeholder, Wrapper } from 'components/ui'
import { getImageUrl } from 'utils'
import { FC, useMemo } from 'react'
import { SEARCH_RESULTS } from 'routes/paths'

export type CategoriesType = {
  pages?: Array<'Comedy' | 'Sport' | 'Concert'>
}

const PAGES = ['Concert', 'Sport', 'Comedy'] as CategoriesType['pages']
//                                 K-POP prod
const HIDDEN_CATEGORIES = ['U3RhckNhdGVnb3J5Tm9kZToxNDA=', process.env.NEXT_PUBLIC_COMEDY_CATEGORY]

const Categories: FC<CategoriesType> = ({ pages = ['Concert', 'Sport', 'Comedy'] }) => {
  const variablesUse = PAGES.reduce(
    (prev, cur) => ({ ...prev, [`use${cur}`]: !!pages.includes(cur) }),
    {} as AllCategoriesQueryVariables
  )
  const { data, loading } = useQuery<AllCategoriesQuery, AllCategoriesQueryVariables>(
    ALL_CATEGORIES,
    {
      variables: {
        concertCategoryId: process.env.NEXT_PUBLIC_CONCERTS_CATEGORY,
        comedyCategoryId: process.env.NEXT_PUBLIC_COMEDY_CATEGORY,
        sportCategoryId: process.env.NEXT_PUBLIC_SPORTS_CATEGORY,
        ...variablesUse,
      },
    }
  )

  const allCategories = useMemo(
    () =>
      data
        ? [
            ...(data.concerts?.edges || []),
            ...(data.sports?.edges || []),
            ...(data.comedy?.edges || []),
          ]
        : [],
    [data]
  )

  const dataSource = useMemo(
    () =>
      allCategories
        .map(({ node }) => node)
        .sort((a, b) => a.position - b.position)
        .filter((item) => {
          if (pages.includes('Concert')) {
            return !HIDDEN_CATEGORIES.includes(item.id)
          }
          return true
        }),
    [data]
  )

  if (!allCategories.length && !loading) return null

  return (
    <Wrapper style={{ padding: '0 0 0 1em' }}>
      <Carousel<typeof dataSource[0]>
        dataSource={dataSource}
        placeholderItem={
          <Grid gap={15} width="100%" height="100%">
            <Placeholder style={{ height: 160 }} />
            <Placeholder style={{ height: 30, width: '80%' }} />
            <Placeholder style={{ height: 20, width: '50%' }} />
          </Grid>
        }
        renderItem={({ image, name, id }) => (
          <Card
            key={id}
            aspectRatio="270/160"
            imageSrc={getImageUrl(image, 500, 300)}
            link={`${SEARCH_RESULTS}?categoryIds=${id}`}
            linkTitle="explore"
            title={name}
          />
        )}
        title="Categories"
        loading={loading}
      />
    </Wrapper>
  )
}

export default Categories
