import { Wrapper } from '~ui'
import StarsTicketsVIP from 'components/tickets/vip-tickets'

const VipTickets = (settings) => {
  const { 'vip-stars-order': idsAndOrder, title } = settings

  return (
    <Wrapper>
      <StarsTicketsVIP idsAndOrder={idsAndOrder} title={title} />
    </Wrapper>
  )
}

export default VipTickets
