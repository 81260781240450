import { FC, useState } from 'react'
import { Markdown, SubscribeModal } from '~shared'
import { Flex, GlowHeading, Typography, Button, Image } from '~ui'

import styles from '../../Marketing.module.scss'

const { Text } = Typography

export type MarketingBlockHeroProps = {
  title?: string
  imageUrl?: string
  preview?: boolean
  description?: string
  mobileImageUrl?: string
  sendGridListId?: string
  showSubscriptionModal?: boolean
}

const MarketingBlockHero: FC<MarketingBlockHeroProps> = ({
  title,
  preview,
  imageUrl,
  description,
  mobileImageUrl,
  sendGridListId,
  showSubscriptionModal,
}) => {
  const withSubscribeModal = sendGridListId && showSubscriptionModal

  const [subModalVisible, setSubModalVisible] = useState(false)

  return (
    <section>
      {withSubscribeModal && (
        <SubscribeModal
          title="Sign up for updates"
          text="Announcements will be sent directly to your inbox."
          visible={subModalVisible}
          sendGridListId={sendGridListId}
          onClose={() => setSubModalVisible((prev) => !prev)}
        />
      )}
      {!preview && (
        <div
          className={styles.hero}
          style={{
            background: `url(${imageUrl})`,
            backgroundColor: '#040404',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        >
          <Flex className={styles['hero-container']}>
            <Flex className={styles['hero-content']}>
              {title && (
                <GlowHeading Tag="h2" size="h1">
                  {title}
                </GlowHeading>
              )}
              <div style={{ height: 20 }} />
              {description && (
                <Text color="g-gray-1">
                  <Markdown md={description} />
                </Text>
              )}
              <div style={{ height: 40 }} />
              <Button
                type="button"
                variant="primary"
                onClick={() => {
                  if (withSubscribeModal) {
                    setSubModalVisible((prev) => !prev)
                    return
                  }

                  document.getElementById('lots')?.scrollIntoView()
                }}
                className={styles['hero-button']}
              >
                {withSubscribeModal ? 'Get Notified at Launch' : 'View Experiences'}
              </Button>
            </Flex>
            {mobileImageUrl && (
              <img className={styles['hero-image']} src={mobileImageUrl} alt={title} />
            )}
          </Flex>
        </div>
      )}
      {preview && <Image className={styles['preview-image']} src={imageUrl} />}
    </section>
  )
}

export default MarketingBlockHero
