import { gql, useQuery } from '@apollo/client'
import Link from 'next/link'
import { FC, useMemo } from 'react'
import { EVENT } from 'routes/paths'

import { Typography, Button, Grid, Placeholder } from '~ui'
import Wrapper from '~ui/Wrapper'
import { getImageUrl } from '~utils'
import { convertServerDateToClient } from '~utils/time-new'
import SectionWrapper from '../wrapper'

import styles from './Auctions.module.scss'

const { Text } = Typography

type CardProps = {
  imageSrc: string
  title: string
  meta: string[]
}

const Card: FC<CardProps> = ({ imageSrc, title, meta = [] }) => (
  <div className={styles.card}>
    <div className={styles.image}>
      <img src={imageSrc} alt={title} />
    </div>

    <Grid textAlign="center" gridAutoFlow="row" className={styles.meta}>
      <Text size="lg" color="g-gray-6" bold center>
        {title}
      </Text>
      {meta.map((text) => (
        <Text key={text} color="purple" bold center>
          {text}
        </Text>
      ))}
    </Grid>
  </div>
)

type PackageProps = {
  title: string
  link: string
  imageSrc?: string
  isEnded: boolean
}

const Package: FC<PackageProps> = ({ title, imageSrc, link, isEnded }) => (
  <div className={styles.package}>
    {imageSrc && <img width={100} height={100} src={imageSrc} alt={title} />}

    <Grid className={styles.text}>
      <Text color="g-white" size="lg" bold>
        {title}
      </Text>
      <Link href={link}>
        <a>
          <Button variant="primary" tabIndex={-1}>
            {!isEnded ? 'Place bid' : 'Ended'}
          </Button>
        </a>
      </Link>
    </Grid>
  </div>
)

const PackageContainer = ({ id }) => {
  const { data, loading } = useQuery(
    gql`
      query GetBaseArtworkData($id: ID!) {
        artwork(id: $id) {
          artworkOrTourOrArtistImage {
            path
            extension
          }
          endDate
          title
          slug
          id
        }
      }
    `,
    { variables: { id } }
  )

  const imageSrc = useMemo(
    () => data?.artwork.artworkOrTourOrArtistImage,
    [data?.artwork.artworkOrTourOrArtistImage]
  )

  if (loading)
    return (
      <div className={styles.package}>
        <Placeholder style={{ height: 64, width: '100%' }} />
      </div>
    )

  const isEnded = convertServerDateToClient(data?.artwork.endDate).getTime() < new Date().getTime()

  return (
    <Package
      isEnded={isEnded}
      imageSrc={getImageUrl(imageSrc, 400, 350)}
      link={EVENT(data.artwork.slug)}
      title={data.artwork.title}
    />
  )
}

type AuctionsGroupProps = {
  card: CardProps
  auctions: Array<string>
}

const AuctionsGroup: FC<AuctionsGroupProps> = ({ card, auctions }) => (
  <Wrapper>
    <SectionWrapper>
      <div className={styles.auction}>
        <Card title={card?.title} imageSrc={card?.imageSrc} meta={card?.meta} />
        <div className={styles.packages}>
          {auctions?.map((itemId) => (
            <PackageContainer id={itemId} />
          ))}
        </div>
      </div>
    </SectionWrapper>
  </Wrapper>
)

export default AuctionsGroup
