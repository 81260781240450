import { FC, ReactNode, isValidElement } from 'react'
import Markdown from 'components/shared/Markdown'
import { Heading, Icon } from '~ui'
import { IconTypes } from '~ui/Icon'

import styles from './NeonCard.module.scss'

export type NeonCardProps = {
  title: string
  description: string
  colorGlow?: GThemeColorsNew
  iconName?: IconTypes
  after?: ReactNode | string
  onClick?: () => void
}

const NeonCard: FC<NeonCardProps> = ({
  title,
  description,
  after,
  iconName,
  onClick,
  colorGlow = 'red',
}) => (
  <div className={styles.card}>
    <a
      {...(onClick
        ? {
            onClick,
            role: 'link',
            tabIndex: 0,
            className: styles.link,
            'aria-hidden': true,
          }
        : {})}
    >
      {iconName && (
        <div className={styles['icon-container']}>
          <Icon name={iconName} width="4em" height="4em" glow glowColor={colorGlow} />
        </div>
      )}
      <Heading Tag="h2" fontWeight={300} uppercase colorGlow={colorGlow}>
        {title}
      </Heading>
      <div className={styles.description}>
        <Markdown md={description} />
      </div>

      {!!after && (
        <>
          {isValidElement(after) ? (
            <div style={{ marginTop: '1rem' }}>{after}</div>
          ) : (
            <span className={styles.disclaimer}>{after}</span>
          )}
        </>
      )}
    </a>
  </div>
)

export default NeonCard
