import { useQuery } from '@apollo/client'

import { SEARCH_ITEMS_PER_PAGE } from 'constants/constant'
import { formatAuctionEndDate } from 'utils/time'
import { GetAuctionsQuery } from 'gql'
import { Card } from 'components/ui'
import { EVENT } from 'routes/paths'
import { getImageUrl } from 'utils'

import Carousel from 'components/carousel'
import Section from '../section'
import { GET_AUCTIONS } from '../queries'

const PastLots = () => {
  const { data: pastLotsData, loading } = useQuery<GetAuctionsQuery>(GET_AUCTIONS, {
    variables: {
      first: SEARCH_ITEMS_PER_PAGE,
      orderBy: '-end_date, title',
      featuredDate_Isnull: false,
      isActive: false,
      isEnded: true,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  })

  const pastLots = pastLotsData?.allArtworks.edges.map(({ node }) => ({
    fields: [node.title, formatAuctionEndDate(node.endDate, node.venue?.timeZone)],
    imageSrc: getImageUrl(node.artworkOrTourOrArtistImage, 500, 300),
    link: EVENT(node.slug),
  }))

  return (
    <Section title="Past lots">
      <Carousel<typeof pastLots[0]>
        dataSource={pastLots}
        renderItem={({ imageSrc, link, fields }) => (
          <Card
            key={link}
            imageSrc={imageSrc}
            link={link}
            linkTitle="View Lot"
            title={fields[0]}
            subtitle={fields[1]}
          />
        )}
        loading={loading}
      />
    </Section>
  )
}

export default PastLots
