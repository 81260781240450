import { memo, FC } from 'react'

import { useUser } from '~hooks'
import Link, { LinkProps } from '~ui/Link'

const ADMIN_PANEL_PREFIX = '/admin-v2/'

const ENTITY_PATH_IN_ADMIN_PANEL = {
  AUCTION: (id: string) => `${ADMIN_PANEL_PREFIX}auctions?id=${id}`,
  BUY_IT_NOW_OPTION: (id: string) => `${ADMIN_PANEL_PREFIX}events?id=${id}`,
  TOUR: (slug: string) => `${ADMIN_PANEL_PREFIX}tours?slug=${slug}`,
  SECTION: (name: string) => `${ADMIN_PANEL_PREFIX}granted-web-sections?name=${name}`,
}

export type EditLinkProps = {
  entityType?: keyof typeof ENTITY_PATH_IN_ADMIN_PANEL
  id?: string
  href?: string
  label?: string
} & Pick<LinkProps, 'color' | 'classes' | 'size' | 'external'>

const EditLink: FC<EditLinkProps> = ({
  entityType,
  href,
  id,
  classes,
  color = 'red',
  size = 'md',
  external,
  label = 'Edit',
}) => {
  const [user] = useUser()

  const link = ENTITY_PATH_IN_ADMIN_PANEL[entityType]?.(id)

  if (!user || user?.type !== 'ADMIN' || !link) {
    return null
  }

  return (
    <Link
      href={href || link}
      color={color}
      target="_blank"
      classes={classes}
      size={size}
      external={external}
    >
      {label}
    </Link>
  )
}

export default memo(EditLink)
