import { UserType } from 'gql'
import { FC } from 'react'
import { useUser } from '~hooks'
import EditLink from '~shared/EditLink'

import styles from './edit-section-wrapper.module.scss'

type EditSectionWrapperProps = {
  label?: string
} & ({ name?: string; href: string } | { name: string; href?: string })

const EditSectionWrapper: FC<EditSectionWrapperProps> = ({
  label = 'Edit this component',
  children,
  name,
  href,
}) => {
  const [user] = useUser()

  if (user?.type !== UserType.Admin) return <>{children}</>

  return (
    <div className={styles.wrapper}>
      <EditLink
        label={label}
        classes={styles.link}
        href={href}
        id={name}
        entityType="SECTION"
        color="neutral"
        size="sm"
        external
      />
      {children}
    </div>
  )
}

export default EditSectionWrapper
