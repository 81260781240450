import { useQuery } from '@apollo/client'

import { GetAuctionsQuery } from 'gql'
import { Card } from 'components/ui'
import { EVENT } from 'routes/paths'
import { getImageUrl } from 'utils'

import Carousel from 'components/carousel'
import Section from '../section'
import { GET_AUCTIONS } from '../queries'

const FeaturedLots = ({ lots }) => {
  const { data: featuredLots, loading } = useQuery<GetAuctionsQuery>(GET_AUCTIONS, {
    variables: {
      ids: '',
      featuredDate_Isnull: false,
      isActive: true,
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const sortedLots =
    (lots &&
      featuredLots &&
      lots
        .map((lot) =>
          featuredLots?.allArtworks.edges.find(({ node }) => Number(node.pk) === Number(lot.pk))
        )
        .filter(Boolean)) ||
    featuredLots?.allArtworks.edges

  const activeAuctions = sortedLots?.map(({ node }) => ({
    fields: [
      { text: node.title, bold: true, size: 'xl', lineClamp: 2 },
      {
        text: `Current Bid: $${node.currentBid || node.startingBid || 'XXXX'}`,
        gray: true,
      },
    ],
    imageSrc: getImageUrl(node.artworkOrTourOrArtistImage, 500, 350),
    link: EVENT(node.slug),
  }))

  if (!activeAuctions?.length && !loading) {
    return null
  }

  return (
    <Section title="Featured lots">
      <Carousel<typeof activeAuctions[0]>
        dataSource={activeAuctions}
        renderItem={({ imageSrc, link, fields }) => (
          <Card
            key={link}
            imageSrc={imageSrc}
            link={link}
            linkTitle="View Lot"
            title={fields[0].text}
            subtitle={fields[1].text}
          />
        )}
        loading={loading}
      />
    </Section>
  )
}

export default FeaturedLots
