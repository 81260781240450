import FeaturedEvent from 'components/home/sections/featured-event'
import MarketingAboutBlock from 'components/marketing/blocks/about'
import MarketingBlockHero from 'components/marketing/blocks/hero'
import MarketingLotsBlock from 'components/marketing/blocks/lots'
import CardsCarousel from 'components/home/block/cards-carousel'
import HeroSlider from 'components/home/block/hero-slider'
import Categories from 'components/home/block/categories'
import Reviews from 'components/home/block/Reviews'
import Search from 'components/home/block/search'
import Events from 'components/home/block/events'
import Cards from 'components/home/block/cards'
import Image from 'components/home/block/image'
import AuctionsSubscribe from 'components/auctions/auctions-subscribe'
import AuctionsForm from 'components/auctions/auctions-form'
import FeaturedLots from 'components/auctions/featured-lots'
import LiveAuctions from 'components/auctions/live-auctions'
import PastLots from 'components/auctions/past-lots'
import ToursCarousel from 'components/home/block/tours-carousel'
import AuctionsGroup from 'components/home/block/auctions'
import FeaturedStars from 'components/home/block/featured-stars'
import VipTickets from 'components/home/block/vip-tickets'

const sections = {
  featured: (settings, index: number) => <FeaturedEvent {...settings} reverse={index % 2 === 0} />,
  'marketing-about-block': (settings) => <MarketingAboutBlock {...settings} />,
  'marketing-hero-block': (settings) => <MarketingBlockHero {...settings} />,
  'cards-carousel': (settings) => <CardsCarousel {...settings} />,
  'hero-slider': (settings) => <HeroSlider {...settings} />,
  lots: (settings) => <MarketingLotsBlock {...settings} />,
  categories: (settings) => <Categories {...settings} />,
  reviews: (settings) => <Reviews {...settings} />,
  search: (settings) => <Search {...settings} />,
  events: (settings) => <Events {...settings} />,
  cards: (settings) => <Cards {...settings} />,
  image: (settings) => <Image {...settings} />,
  'auctions-subscribe-form': (settings) => <AuctionsSubscribe {...settings} />,
  'live-auctions': (settings) => <LiveAuctions {...settings} />,
  'auctions-form': (settings) => <AuctionsForm {...settings} />,
  'featured-lots': (settings) => <FeaturedLots {...settings} />,
  'past-lots': (settings) => <PastLots {...settings} />,
  'auctions-group': (settings) => <AuctionsGroup {...settings} />,
  'tours-carousel': (settings) => <ToursCarousel {...settings} />,
  'featured-stars': (settings) => <FeaturedStars {...settings} />,
  'vip-tickets': (settings) => <VipTickets {...settings} />,
}

export default sections
