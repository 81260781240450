import classnames from 'classnames'
import { FC, InputHTMLAttributes } from 'react'

import styles from './text-area.module.scss'

export interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  error?: string
  withError?: boolean
}

const TextArea: FC<TextAreaProps> = ({ error, withError = true, ...props }) => (
  <div className={styles.wrapper}>
    <div
      className={classnames(styles['input-wrapper'], {
        [styles.disabled]: props.disabled,
        [styles.error]: error,
      })}
    >
      <textarea {...props} />
    </div>

    {withError && (
      <span
        className={classnames(styles['error-message'], {
          [styles['error-message-active']]: !!error,
        })}
      >
        {error}
      </span>
    )}
  </div>
)

export default TextArea
