import { FC } from 'react'
import { useFormik } from 'formik'
import { object } from 'yup'

import { useEmailSubsribeContactList } from 'hooks'
import { Input, Button, Typography } from 'components/ui'
import { EMAIL } from 'constants/schema'

import styles from './SubscribeForm.module.scss'

const { Text } = Typography

export type SubscribeFormProps = {
  listNames: string[]
  listIds?: string[]
}

const SubscribeForm: FC<SubscribeFormProps> = ({ listIds, listNames }) => {
  const [subscribe, { data, loading }] = useEmailSubsribeContactList()

  const { errors, handleSubmit, values, handleBlur, touched, handleChange } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: ({ email }) =>
      subscribe({
        variables: {
          data: { email },
          listNames,
          listIds,
        },
      }),
    validationSchema: object({
      email: EMAIL(),
    }).required(),
  })

  if (loading)
    return (
      <Text className={styles.text} center>
        Subscribing...
      </Text>
    )

  if (data?.addOrUpdateContactList.__typename === 'ContactMutationSuccess')
    return (
      <Text className={styles.text} center>
        Thanks for subscribing!
      </Text>
    )

  if (data?.addOrUpdateContactList.__typename === 'ContactMutationFailure')
    return (
      <Text className={styles.text} center color="red">
        Oops, something went wrong. Please try again later.
      </Text>
    )

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <Input
        error={touched.email && errors.email}
        onChange={handleChange}
        value={values.email}
        onBlur={handleBlur}
        placeholder="What’s your email?"
        name="email"
        type="email"
        large
      />
      <Button type="submit" className={styles.button} uppercase>
        Subscribe
      </Button>
    </form>
  )
}

export default SubscribeForm
