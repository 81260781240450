import { FC, ReactNode } from 'react'
import { Flex, Grid, Icon, Typography, Image } from '~ui'

import styles from './Testimonial.module.scss'

const { Text } = Typography

export type TestimonialProps = {
  bodyText: string | ReactNode
  customer: {
    imageSrc?: string
    name: string
    role: string
  }
}

const Testimonial: FC<TestimonialProps> = ({ bodyText, customer: { imageSrc, name, role } }) => (
  <Grid className={styles['testimonial-container']}>
    <Icon name="quote" glow glowColor="green" />

    <Text>{bodyText}</Text>

    <Flex className={styles['customer-container']}>
      <Image rounded width={50} height={50} src={imageSrc} alt={`${name} - ${role}`} />

      <div className={styles['customer-text']}>
        <Text>{name}</Text>
        <Text color="light-gray">{role}</Text>
      </div>
    </Flex>
  </Grid>
)

export default Testimonial
