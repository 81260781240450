import { gql } from '@apollo/client'

export const GET_AUCTIONS = gql`
  query GetAuctions(
    $first: Int
    $last: Int
    $after: String
    $orderBy: String
    $title_Icontains: String
    $featuredDate_Isnull: Boolean
    $isActive: Boolean
    $ids: String
    $isEnded: Boolean
  ) {
    allArtworks(
      first: $first
      last: $last
      after: $after
      orderBy: $orderBy
      title_Icontains: $title_Icontains
      featuredDate_Isnull: $featuredDate_Isnull
      salesAs: "auction"
      status: "public"
      isActive: $isActive
      ids: $ids
      isEnded: $isEnded
    ) {
      edges {
        node {
          id
          pk
          title
          currentBid
          startingBid
          slug
          artworkOrTourOrArtistImage {
            id
            path
            extension
          }
          endDate
          artistFullName
          venue {
            id
            timeZone
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const GET_MARKETING_EVENTS_NEW = gql`
  query getMarketingEventsNew($isActive: Boolean) {
    marketingEvents(status: "public") {
      edges {
        node {
          title
          id
          slug
          template {
            about {
              title
              description
              bannerImageUrl
            }
            hero {
              description
              mobileImageUrl
            }
          }

          artworks(isActive: $isActive, featuredDate_Isnull: false) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

export default GET_AUCTIONS
