import { gql, useQuery } from '@apollo/client'
import { differenceInSeconds, isAfter } from 'date-fns'

import { GetMarketingEventRelatedAuctionsQuery } from 'gql'
import { EVENT } from 'routes/paths'
import { useScreenClass } from '~hooks'
import { Carousel } from '~shared'
import { Card, GlowHeading, Grid } from '~ui'
import { formatAuctionEndDate, formatCurrency, getImageUrl, itemsToShow, time } from '~utils'

import styles from '../../Marketing.module.scss'

export const GET_MARKETING_EVENT_RELATED_AUCTIONS = gql`
  query GetMarketingEventRelatedAuctions($ids: String!, $announceDate_Lt: DateTime) {
    allArtworks(
      status: "public"
      announceDate_Lt: $announceDate_Lt
      orderBy: "auctions__end"
      ids: $ids
    ) {
      edges {
        node {
          id
          title
          currentBid
          endDate
          startDate
          slug
          salesAs
          venue {
            id
            timeZone
          }
          artworkOrTourOrArtistImage {
            id
            path
            extension
          }
        }
      }
    }
  }
`

const MarketingBlockLots = ({ announceDate, auctions, title: sectionTitle }) => {
  const { data, loading } = useQuery<GetMarketingEventRelatedAuctionsQuery>(
    GET_MARKETING_EVENT_RELATED_AUCTIONS,
    {
      variables: {
        ids: auctions.join(','),
        announceDate_Lt: announceDate,
      },
    }
  )

  const auctionsList = (data?.allArtworks.edges || [])
    .map(
      ({
        node: {
          title,
          currentBid,
          endDate,
          artworkOrTourOrArtistImage,
          venue,
          startDate,
          slug: s,
          salesAs,
          id,
        },
      }) => {
        const isAuctionStarted = isAfter(new Date(), time.convertServerDateToClient(startDate))
        const isAuctionEnded =
          differenceInSeconds(
            time.convertServerDateToTz(endDate, venue.timeZone),
            time.convertServerDateToTz(new Date(), venue.timeZone)
          ) < 0

        return {
          id,
          title,
          salesAs,
          link: EVENT(s),
          isEnded: isAuctionEnded,
          subtitle: `Current bid: ${formatCurrency(currentBid || 0, 0)}`,
          backgroundImage: getImageUrl(artworkOrTourOrArtistImage, 290, 215),
          label: !isAuctionStarted
            ? `Starts ${time.formatDate(
                time.convertServerDateToTz(startDate, venue.timeZone),
                'dayAndTime'
              )}`
            : formatAuctionEndDate(endDate, venue.timeZone, 'short'),
        }
      }
    )
    .sort((a, b) => auctions.indexOf(a.id) - auctions.indexOf(b.id))
    .reverse()

  const { screenClass } = useScreenClass()

  if (!loading && !auctionsList.length) {
    return null
  }

  const showControls = !['sm', 'xs'].includes(screenClass)
  const itemsEnough = auctionsList.length > itemsToShow(screenClass)

  return (
    <section id="lots">
      <Grid className={styles.lots}>
        <Grid className={styles['lots-container']} gap={18}>
          {sectionTitle && (
            <GlowHeading Tag="h2" glow={false} fill>
              {sectionTitle}
            </GlowHeading>
          )}

          {!loading && (
            <Carousel
              itemsToShow={itemsToShow(screenClass)}
              showControls={showControls && itemsEnough}
              loading={loading}
            >
              {auctionsList
                .filter(({ isEnded }) => !isEnded)
                .map(({ link, backgroundImage, title, label, salesAs }) => (
                  <div key={link}>
                    <Card
                      imageSrc={backgroundImage}
                      link={link}
                      linkTitle={salesAs === 'AUCTION' ? 'Place Bid' : 'Enter Now'}
                      subtitle={label}
                      title={title}
                    />
                  </div>
                ))}
              {auctionsList
                .filter(({ isEnded }) => isEnded)
                .map(({ link, backgroundImage, title, label, salesAs }) => (
                  <div key={link} style={{ filter: 'grayscale(0.4)', opacity: 0.5 }}>
                    <Card
                      imageSrc={backgroundImage}
                      link={link}
                      linkTitle={salesAs === 'AUCTION' ? 'Place Bid' : 'Enter Now'}
                      subtitle={label}
                      title={title}
                    />
                  </div>
                ))}
            </Carousel>
          )}
        </Grid>
      </Grid>
    </section>
  )
}

export default MarketingBlockLots
