import { FC } from 'react'
import NeonCard, { NeonCardProps } from 'components/ui/NeonCard'

import Wrapper from '~ui/Wrapper'
import { smoothScroll } from '~utils'
import SectionWrapper from '../wrapper'

import styles from './Cards.module.scss'

const Cards: FC<{ cards: NeonCardProps[]; onClickScrollToSectionId: string }> = ({
  cards,
  onClickScrollToSectionId,
}) => (
  <Wrapper>
    <SectionWrapper>
      <div className={styles.cards}>
        {cards?.map(({ description, title, after, colorGlow }) => (
          <NeonCard
            onClick={() => {
              const el = document.getElementById(onClickScrollToSectionId)

              if (el) {
                smoothScroll(window.pageYOffset + el.getBoundingClientRect().top - 120)
              }
            }}
            key={title}
            title={title}
            description={description}
            after={after}
            colorGlow={colorGlow}
          />
        ))}
      </div>
    </SectionWrapper>
  </Wrapper>
)

export default Cards
